import { userAxios } from '@/services'

const promotersList = async (perPage) => {
    try {
        return await userAxios.get(`promoter/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const promotersGenerateCupon = async (data) => {
    try {
        return await userAxios.post(`promoter/cupon/generate`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const promotersCreate = async (data) => {
    try {
        return await userAxios.post('promoter/create', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const promotersUpdate = async (id, data) => {
    try {
        return await userAxios.put(`promoter/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const promotersDelete = async (id) => {
    try {
        return await userAxios.delete(`promoter/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const promotersPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`promoter/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const promotersFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`promoter/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const promotersFilterPagination = async (perPage, page, data) => {
    try {
        return await userAxios.post(`promoter/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    promotersList,
    promotersGenerateCupon,
    promotersCreate,
    promotersUpdate,
    promotersDelete,
    promotersPagination,
    promotersFilter,
    promotersFilterPagination
}
<template>
  <b-sidebar
    id="add-new-promoters-sidebar"
    :visible="isAddNewPromotersSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-promoters-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("Add") }} {{ $t("New") }} Promoters</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            :rules="isDisabledName ? '' : 'required'"
          >
            <b-form-group :label="$t('Form.Name')" label-for="name">
              <b-form-input
                id="name"
                v-model="stateData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :disabled="isDisabledName"
                placeholder="Type promoters name"
                @change="addName()"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- laboratory -->
          <validation-provider
            #default="validationContext"
            name="Users"
            :rules="isDisabledUser ? '' : 'required'"
          >
            <b-form-group label="Users" label-for="Users">
              <v-select
                id="Users"
                v-model="stateData.users_id"
                multiple
                :state="getValidationState(validationContext)"
                @search="searchUsers"
                label="name"
                :disabled="isDisabledUser"
                :reduce="(value) => value.id"
                :options="optionsUsers"
                @input="addUser"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="loading"
            >
              <span v-if="!loading">{{ $t("Add") }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-danger"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import vSelect from "vue-select";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { onMounted, ref } from "@vue/composition-api";

import axiosCT from "@/services/admin/consults/promoters";
import axiosU from "@/services/admin/user";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewPromotersSidebarActive",
    event: "update:is-add-new-promoters-sidebar-active",
  },
  props: {
    isAddNewPromotersSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const optionsStatus = [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ];
    const stateDataTem = {
      name: "",
      users_id: [],
    };
    const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)));

    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(stateDataTem));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    onMounted(() => {
      getUsers();
    });

    const clearTimeoutBuscador = ref("");
    const searchUsers = (name, loading) => {
      if (name.length) {
        const datos = {
          name,
          role: ["PROMOTER"],
        };
        loading(true);
        clearTimeout(clearTimeoutBuscador.value);
        clearTimeoutBuscador.value = setTimeout(() => {
          axiosU.userFilter(10, datos).then(({ registro: { data } }) => {
            optionsUsers.value = data;
            loading(false);
          });
        }, 400);
      }
    };

    const optionsUsers = ref([]);
    const getUsers = () => {
      axiosU.userList(30).then(({ data }) => {
        const userByRoles = data.filter((item) => item.roles[0].id === 9);
        optionsUsers.value = userByRoles;
      });
    };

    const isDisabledUser = ref(false);
    const isDisabledName = ref(false);
    const addName = () => {
      stateData.value.name.length > 0 ? isDisabledUser.value = true : isDisabledUser.value = false
    };

    const addUser = () => {
      stateData.value.users_id.length > 0 ? isDisabledName.value = true : isDisabledName.value = false
    };

    const loading = ref(false);
    const onSubmit = () => {
      loading.value = true;
      const datos = {
        ...stateData.value,
        users_id: stateData.value.users_id.join(),
      };
      axiosCT
        .promotersCreate(datos)
        .then(({ status, registro }) => {
          if (status == "ERROR") {
            emit("createPromoters", { status: false });
          } else {
            loading.value = false;
            resetuserData();
            emit("createPromoters", { status: true, registro });
          }
        })
        .catch(() => {
          emit("createPromoters", { status: false });
          loading.value = false;
        });
    };
    return {
      loading,
      stateData,
      optionsStatus,
      optionsUsers,
      onSubmit,
      searchUsers,
      addName,
      addUser,
      isDisabledUser,
      isDisabledName,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style></style>
